.Highlights {
  width: 100%;
  position: relative;
  padding: 5rem 0;
}

.highlights-item {
  display: flex;
  gap: 2rem;
}

.highlights-left {
  position: relative;
  z-index: 2;
  width: 40%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #008e98a4;
  border-radius: 0px 25px 25px 0px;
}
.highlights-left h2 {
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
  color: white;
}

.highlights-right {
  width: 50%;
}
.highlights-right img {
  width: 60%;
  object-fit: cover;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media (min-width: 1200px) {
}

@media only screen and (max-width: 680px) {
  .highlights-item {
    flex-wrap: wrap;
  }
  .highlights-left {
    width: 100%;
    padding: 3rem 1rem 1rem 1rem;
  }
  .highlights-right {
    width: 100%;
  }

  .highlights-right img {
    width: 100%;
  }
}
