.list-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.list-table th,
.list-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.list-table th,
.list-table td{
    border-left: 1px solid #ddd;
    text-align: center;
}

.list-table th {
  background-color: #008e98;
  color: white;
}

.list-table h5{
    text-align: center;
    margin: 4rem 0rem 2rem 0px;
}
