.Footer {
  padding: 6rem 0 0 0;
  width: 100%;
  position: relative;
  background-color: white;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.3);
}

.Footer-main,
.footer-down {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  gap: 2rem;
  position: relative;
  z-index: 2;
}

.footer-detail-box {
  display: grid;
  grid-template-columns: 25% 20% 30% 20%;
  gap: 1rem;
  /* padding-top: 4rem;
  margin-top: 4rem; */
}

.footer-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 15px 0;
  border-top: 1px solid #adadad;
}

.footer-left img {
  width: 80%;
  margin-bottom: 1rem;
}

.footer-right-quick h2 {
  font-size: 18px;
  margin-bottom: 1rem;
  padding-right: 30px;
}
.footer-right-quick h2:after {
  content: "";
  background-color: #008e98;
  display: flex;
  height: 2px;
  width: 30%;
  margin-top: 5px;
}

.footer-right-quick ul {
  /* display: flex; */
  padding-left: 18px;
  margin: 0;
  /* flex-direction: column; */
}
.footer-right-quick ul li {
  /* display: flex; */
  align-items: center;
  padding-right: 20px;
}
.footer-right-quick ul li a {
  text-decoration: none;
  color: black;
  font-size: 14px;
  padding-bottom: 5px;
}
.footer-right-quick ul li a:hover {
  text-decoration: none;
  color: #008e98;
}

.footer-social-media{
  margin-top: 1rem;
}

.footer-social-media svg {
  font-size: 16px;
  position: relative;
}

.footer-social-media a {
  position: relative;
  /* display: block; */
  line-height: 42px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  /* font-size: 14px; */
  color: black;
  /* background-color:  rgb(42 127 37); */
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin: 0px 15px 0px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-media a:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #008e98;
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 50px;
}
.footer-social-media a:hover:before {
  transform: scale(1);
}
.footer-social-media a:hover {
  color: white;
}

.footer-copyright p {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin: 0;
}

.footer-copyright-menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.footer-copyright-menu ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.footer-copyright-menu ul li a {
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-size: 12px;
}
.footer-copyright-menu ul li a:hover {
  color: #008e98;
}

.footerlogo-down {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerlogo-down img{
  width: 25%;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .Footer-main {
    max-width: 1140px;
  }

  .footer-down {
    max-width: 1140px;
  }
}


@media only screen and (max-width: 990px) {
  .footer-detail-box {
    grid-template-columns: repeat(2,1fr);
  }
}

@media only screen and (max-width: 680px) {
  .footer-down {
    flex-direction: column;
    gap: 15px;
  }

  .Footer-main {
    gap: 0;
    display: block;
  }

  .footer-left {
    width: 100%;
  }

  .footer-left a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-left img {
    width: 50%;
  }

  .Footer-main {
    max-width: 520px;
  }
  .footer-right-quick h2:after {
    width: 15%;
  }

  .footer-detail-box {
    grid-template-columns: repeat(1,1fr);
  }

  .footer-right-quick ul li {
    padding-bottom: 5px;
  }

  .footer-social-media .media-items {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .footer-copyright-logo a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-copyright-menu ul {
    padding: 0;
    flex-flow: wrap;
    margin: 0;
  }

  .footer-copyright-menu ul li {
    display: block;
    /* padding: 0; */
    padding-bottom: 5px;
  }

  .footer-copyright {
    justify-content: center;
  }

  .footerlogo-down img {
    margin-top: 1rem;
    width: 60%;
    object-fit: cover;
}

}


