.PageBanner {
  position: relative;
  margin-top: 2rem;
}
.PageBanner img {
  width: 100%;
  object-fit: cover;
  height: 60vh;
}
.PageBanner:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 0;
  display: inline-block;
  opacity: 0.9;
  background-image: linear-gradient(
    0deg,
    #000000 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.PageBanner h1 {
  position: absolute;
  bottom: 12%;
  color: white;
  z-index: 2;
  font-size: 50px;
  padding: 2rem;
}
.PageBanner p {
  position: absolute;
  bottom: 0;
  color: white;
  z-index: 2;
  font-size: 18px;
  padding: 2rem;
  width: 60%;
  margin: 0;
}
.PageBanner h1:after {
  content: "";
  margin-top: 2px;
  display: flex;
  height: 2px;
  width: 70%;
  border-radius: 50px;
  background-color: #008e98;
}

@media only screen and (max-width: 680px) {
  .PageBanner h1 {
    font-size: 30px;
  }
  .PageBanner p {
    width: 100%;
    font-size: 12px;
  }

  .PageBanner h1 {
    bottom: 15%;
  }
}
