.Walkthrough {
  padding: 3rem 0rem;
  width: 100%;
  background-color: #008e98;
}

.Walkthrough-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Walkthrough-main h2 {
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.walkthrough-item {
  display: flex;
  gap: 1rem;
}

.walkthrough-left {
  width: 60%;
}

.walkthrough-left img {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.walkthrough-left iframe {
  width: 100%;
  height: 100%;
}
.walkthrough-right {
  width: 40%;
}

.walkthrough-form {
  padding: 2rem;
  border-radius: 8px;
  background-color: #fafafa;
}
.walkthrough-form h4 {
  font-size: 18px;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .Walkthrough-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
  .walkthrough-item {
    flex-wrap: wrap;
  }

  .walkthrough-left,
  .walkthrough-right {
    width: 100%;
  }

  .category-card {
    height: 200px;
  }

  .walkthrough-left {
    grid-template-columns: repeat(1, 1fr);
  }
}
