.PresClients {
    padding: 5rem 0rem;
    width: 100%;
    background-color: #f1f1f1;
  }
  
  .PresClients-main {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .hService-heading{
    text-align: center;
  }

  .presClients-card{
    margin: 1rem;
  }

  .presClients-image img{
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
    aspect-ratio: 5/3;
    background-color: white;
    border: 1px solid rgb(196, 196, 196);
  }

  @media (min-width: 1200px) {
    .PresClients-main {
      max-width: 1140px;
    }
  }

  @media only screen and (max-width: 680px) {
    
  }