.CallAction {
  padding: 5rem 0rem;
  background: #008e98;
  width: 100%;
}

.CallAction-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.CallAction-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}
.CallAction-box h2{
    font-size: 45px;
    color: white;
    font-weight: 700;
    width: 50%;
}
.callAction-btn a{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    text-decoration: none;
    color: #008e98;
    padding: 20px 60px;
    font-size: 30px;
    font-weight: 700;
}
.callAction-btn a svg{
   margin-right: 10px;
   font-size: 40px;
}

@media (min-width: 1200px) {
  .CallAction-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
    .CallAction-box{
        flex-wrap: wrap;
    }
    .CallAction-box h2{
        width: 100%;
    }
    .callAction-btn {
        width: 100%;
    }
}
