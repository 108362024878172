.aboutPage-right img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 3/2;
}

.aboutPage-right {
  width: 50%;
}

@media only screen and (max-width: 680px) {
  .aboutPage-right {
    width: 100%;
    order: 2;
  }
  .about-degit h3 {
    font-size: 80px;
    font-weight: 700;
    color: #008e98;
    position: relative;
    margin-right: 7rem;
    margin-bottom: 0;
  }

  .about-degit h3 span {
    font-size: 50px;
  }

  .about-degit-title p {
    left: 41%;
  }
}
